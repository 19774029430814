import Vue from 'vue'
import Router from 'vue-router'
import jwt from 'jsonwebtoken'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

// Profiles
const Profiles = () => import('@/views/profiles/Profiles')
const AddProfile = () => import('@/views/profiles/AddProfile')
const UpdateProfile = () => import('@/views/profiles/UpdateProfile')
const DetailProfile = () => import('@/views/profiles/DetailProfile')

// Broadcast
const SendBroadcast = () => import('@/views/broadcast/SendBroadcast')
const BroadcastHistory = () => import('@/views/broadcast/BroadcastHistory')
const BroadcastHistoryDetail = () => import('@/views/broadcast/BroadcastHistoryDetail')

// Conversation
const Conversation = () => import('@/views/conversations/Conversation')
const Inbox = () => import('@/views/conversations/Inbox')

// SmsTemplate
const SmsTemplates = () => import('@/views/smstemplates/SmsTemplates')
const AddSmsTemplate = () => import('@/views/smstemplates/AddSmsTemplate')
const UpdateSmsTemplate = () => import('@/views/smstemplates/UpdateSmsTemplate')

// Login
const TokenLogin = () => import('@/views/login/TokenLogin')

// Logout
const Logout = () => import('@/views/logout/Logout')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '',
          name: 'TokenLogin',
          component: TokenLogin,
          meta: {
            requiresAuth: false
          },
        }
      ]
    },
    {
      path: '/logout',
      name: 'Logout',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '',
          name: 'Logout',
          component: Logout,
          meta: {
            requiresAuth: true
          },
        }
      ]
    },
    {
      path: '/',
      redirect: '/profiles',
      name: 'Home',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'profiles',
          name: 'Profiles',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'All Profiles',
              component: Profiles,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add',
              name: 'Add Profile',
              component: AddProfile,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'update/:id',
              name: 'Update Profile',
              component: UpdateProfile,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'detail/:id',
              name: 'Detail Profile',
              component: DetailProfile,
              meta: {
                requiresAuth: true
              },
            },
          ]
        },
        {
          path: 'broadcast',
          name: 'Broadcast',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'send',
              name: 'Send Broadcast',
              component: SendBroadcast,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'history',
              name: 'Broadcast History',
              component: BroadcastHistory,
              meta: {
                requiresAuth: true
              }
            },
            {
              path: 'historydetail/:id',
              name: 'Broadcast History Detail',
              component: BroadcastHistoryDetail,
              meta: {
                requiresAuth: true
              },
            }
          ]
        },
        {
          path: 'conversation',
          name: 'Conversation',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'inbox',
              name: 'Inbox',
              component: Inbox,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'detail/:phone_number',
              name: 'Conversation',
              component: Conversation,
              meta: {
                requiresAuth: true
              },
            }
          ]
        },
        {
          path: 'smstemplates',
          name: 'SMS Templates',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'All SMS Templates',
              component: SmsTemplates,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'add',
              name: 'Add SMS Template',
              component: AddSmsTemplate,
              meta: {
                requiresAuth: true
              },
            },
            {
              path: 'update/:key',
              name: 'Update SMS Template',
              component: UpdateSmsTemplate,
              meta: {
                requiresAuth: true
              },
            }
          ]
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        },
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/coreui-icons',
          name: 'CoreUI Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'Icons library',
              component: CoreUIIcons
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        // {
        //   path: 'login',
        //   name: 'Login',
        //   component: Login
        // },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      // next({
      //   path: '/pages/login',
      //   params: { nextUrl: to.fullPath }
      // });
      if (process.env.NODE_ENV === "production") {
        window.location.href = process.env.VUE_APP_LOGIN_URL;
      } else {
        localStorage.setItem('jwt', process.env.VUE_APP_JWT);
        localStorage.setItem('refresh_token', process.env.VUE_APP_REFRESH_TOKEN);
        localStorage.setItem('username', process.env.VUE_APP_USERNAME);
        next();
      }
    } else {
      var decoded = jwt.decode(localStorage.getItem('jwt'));
      if (decoded != null && decoded.exp != null) {
        if (decoded.exp - parseInt(Date.now() / 1000 - 10) < 0) {
          localStorage.removeItem('jwt');
          // next({
          //   path: '/pages/login',
          //   params: { nextUrl: to.fullPath }
          // });
          window.location.href = process.env.VUE_APP_LOGIN_URL;
        } else {
          next();
        }
      } else {
        localStorage.removeItem('jwt');
        // next({
        //   path: '/pages/login',
        //   params: { nextUrl: to.fullPath }
        // });
        window.location.href = process.env.VUE_APP_LOGIN_URL;
      }
      
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    next();
  } else {
    next();
  }
});

export default router;